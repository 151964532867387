import { generateId } from "./string"

/**
 * It's a simple custom hash
 * Just generate a random string and concat with the base64 of the target number
 * 
 * @param num 
 * @returns 
 */

export const customHash = (num: number): string => {
  // Generate a random UID of exactly 8 characters
  const randomUid = generateId(8); // Ensure this returns an 8-character string
  
  // Encode the number as Base64
  if (!Number.isInteger(num) || num < 0) {
    throw new Error("Invalid number. Must be a positive integer.");
  }
  const base64 = btoa(num.toString());

  // Combine and return the custom hash
  return `${randomUid}${base64}`;
};