import ModeSwitch from "src/components/buttons/ModeSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useSwipe } from "src/hooks/useSwipe";
import CoinBalance from "./CoinBalance";
import SideNavItems from "../desktop/SideNavItems";
import useNav from "src/hooks/useNav";
import useTheme from "src/hooks/useTheme";
import ThemeChooser from "src/components/buttons/ThemeChooser";
import AlertButton from "../desktop/AlertButton";
import HelpButton from "../desktop/HelpButton";
import BannerAlertMobile from "src/components/banners/BannerAlertMobile";
import Upgrade from "../desktop/Upgrade";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import ZoneButton from "src/components/buttons/ZoneButton";

const HeaderBarNav = () => {
  const { open, setOpen, isDisabled } = useNav();

  const { mode } = useTheme();

  const { app } = useApp();

  const { subscription } = useCurrentSubscriptionContext();

  useSwipe({
    onSwipeRight: () => setOpen(false),
    onSwipeLeft: () => {},
    swipeThreshold: 90,
  });

  return (
    <header className="flex-no-wrap fixed z-10 flex min-w-full items-center justify-around bg-card-light p-3 dark:bg-card-dark">
      <>
        {open && (
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.69)",
            }}
            onClick={() => setOpen(!open)}
            className={
              open
                ? "absolute bottom-0 left-0 top-0 z-40 min-h-screen w-full transition-all duration-300"
                : "absolute bottom-0 left-0 top-0 z-40 min-h-screen w-full transition-all duration-300"
            }
          ></div>
        )}
        <div
          id="menu"
          className={
            open
              ? "absolute bottom-0 right-0 top-0 z-40 -mr-0 min-h-screen w-64 bg-card-light duration-300 dark:bg-card-dark md:w-72"
              : "absolute bottom-0 right-0 top-0 z-40 -mr-[450px] min-h-screen w-64 bg-card-light duration-300 dark:bg-card-dark md:w-72"
          }
        >
          <nav className="fixed bottom-0 top-0 w-64 bg-card-light px-2 py-4 dark:bg-card-dark xs:px-1">
            <div className="flex w-full items-center justify-center">
              <div onClick={() => setOpen(!open)}>
                <HelpButton />
              </div>
              {app?.hasPurchaseableZones && subscription && (
                <ZoneButton subscription={subscription} />
              )}
              <AlertButton />
              <ModeSwitch className="text-primary" />
              {app?.hasThemes && <ThemeChooser />}
            </div>
            <SideNavItems />
            <div className="h-full w-full p-4 pt-8">
              <Upgrade />
            </div>
            <BannerAlertMobile />
          </nav>
        </div>
      </>

      <div className="flex h-8 w-full items-center justify-between">
        <div className="-mr-10 flex w-full items-center justify-start">
          <CoinBalance />
        </div>

        <div className="mt-1 flex w-full items-center justify-center ">
          <img
            src={mode === "light" ? app?.logoPathLight : app?.logoPathDark}
            alt={`${app?.name || getHostName()} Logo`}
            className={app?.id === 1 ? "" : "w-12"}
          />
        </div>

        <div className="-ml-10 mr-0.5 mt-1.5 flex w-full items-center justify-end">
          <button>
            <FontAwesomeIcon
              icon={faBars}
              className="h-6 text-primary md:h-8"
              onClick={isDisabled ? () => {} : () => setOpen(!open)}
            />
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderBarNav;
